import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@mui/material";

import {
  AudienceDropdown, AudienceDropdownOption,
  CountryDropdown, Dropdown,
  DropdownOption,
  GroupedDropdownOption,
  SpinnerWithText,
} from "@/components/atoms";
import { PageLayout } from "@/components/layouts";
import { getInsight } from "@/services";
import {
  AudienceSizeStats,
  FanOpportunitiesTab,
  FanSummaryTab,
  HowToReachThemTab,
  OtherSportsTab,
  SponsorshipTab,
  SpendTab,
  Tab,
  Tabs,
} from "@/components/molecules";
import { Insight } from "@/types";
import { reformatData } from "@/utils/common";

export const HomePage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [audience, setAudience] = React.useState<AudienceDropdownOption>({
    id: 0,
    name: "",
    group: "",
    countries: []
  });
  const [country, setCountry] = React.useState<DropdownOption | null>(null);
  const [insight, setInsight] = React.useState<Insight | null>(null);
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  useEffect(() => {
    let isMounted = true;

    const getInsightData = async () => {
      if (audience && country) {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getInsight(
          accessToken,
          country.id,
          audience.id
        );

        if (!isMounted) {
          return;
        }

        if (data) {
          setInsight(data);
          setIsLoading(false);
        }

        if (error) {
          setIsLoading(false);
          console.log(error);
        }
      }
    };
    getInsightData();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, audience, country]);

  return (
    <PageLayout>
      <Grid container item xs={9} spacing={2} justifyContent="center">
        <Grid container item xs={12} spacing={2} justifyContent="flex-start">
          <Grid item xs={4}>
            <AudienceDropdown
              value={audience}
              onChange={(selectedAudience) => setAudience(selectedAudience)}
            />
          </Grid>
          <Grid item xs={4}>
            {/*<CountryDropdown*/}
            {/*  value={country}*/}
            {/*  onChange={(selectedCountry) => setCountry(selectedCountry)}*/}
            {/*/>*/}
            <Dropdown
              label="Select Your Country"
              options={audience.countries}
              value={country ? country.name : ""}
              onChange={(name) => setCountry(audience.countries?.find((c) => c.name === name))}
            />
          </Grid>
        </Grid>
        {insight && !loading && (
          <>
          <AudienceSizeStats audienceSize={insight.audience_size} />
            <Grid container item xs={12} spacing={2} mt={2}>
              <Tabs
                selectedTab={selectedTab}
                onTabChange={(tabIndex) => setSelectedTab(tabIndex)}
              >
                <Tab title="Fan Summary">
                  <FanSummaryTab
                    demographics={insight.demographics}
                    surveyResults={insight.survey}
                    opportunityResults={insight.opportunity}
                  />
                </Tab>
                <Tab title="Other Sports">
                  <OtherSportsTab
                    sports={reformatData(insight.scores.sports)}
                  />
                </Tab>
                <Tab title="Commercial Opportunities">
                  <SponsorshipTab
                    verticals={reformatData(
                      insight.scores.verticals.filter(
                        (vertical) =>
                          vertical.variable_group === "Commercial Opportunities"
                      )
                    )}
                  />
                </Tab>
                <Tab title="Fan Opportunities">
                  <FanOpportunitiesTab
                    verticals={reformatData(
                      insight.scores.verticals.filter(
                        (vertical) =>
                          vertical.variable_group === "Fan Opportunities"
                      )
                    )}
                  />
                </Tab>
                <Tab title="How To Reach Them">
                  <HowToReachThemTab media={insight.scores.media} />
                </Tab>
                <Tab title="Category Spend">
                  <SpendTab categories={reformatData(insight.scores.spend)} />
                </Tab>
              </Tabs>
            </Grid>
          </>
        )}
        {loading && (
          <Grid container item lg={12} justifyContent="center" mt={30}>
            <SpinnerWithText text="Data is loading" />
          </Grid>
        )}
      </Grid>
    </PageLayout>
  );
};
