import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import { Tab, Table, Tabs } from "@/components/molecules";
import {
  topPlayersColumns,
  topSportsColumns,
  topTeamsColumns,
} from "@/components/molecules/table";
import { League, Player, Sport, Team } from "@/types";
import { topLeaguesColumns } from "@/components/molecules/table/constants";
import { reformatData } from "@/utils/common";

type OtherSportsTabProps = {
  sports: Sport[];
};

export const OtherSportsTab: React.FC<OtherSportsTabProps> = ({ sports }) => {
  const [selectedSport, setSelectedSport] = useState<Sport>();
  const [teams, setTeams] = useState<Team[]>([]);
  const [leagues, setLeagues] = useState<League[]>([]);
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleSportChange = (sport: Sport) => {
    setSelectedSport(sport);
    setTeams(reformatData(sport.teams));
    setLeagues(reformatData(sport.leagues));
    setPlayers(reformatData(sport.players));
  };

  useEffect(() => {
    setSelectedSport(sports[0]);
    setTeams(reformatData(sports[0].teams));
    setLeagues(reformatData(sports[0].leagues));
    setPlayers(reformatData(sports[0].players));
  }, []);

  return (
    <Grid container item xs={12} spacing={6} mb={12}>
      <Grid item xs={12} sm={12} md={6} lg={6} mb={4}>
        <Typography variant="subtitle1" mb={2}>
          Top Sports
        </Typography>
        <Table
          id="top-sports"
          data={sports}
          columns={topSportsColumns}
          onRowClick={(sport: Sport) => handleSportChange(sport)}
          startSelected={0}
        />
      </Grid>
      {selectedSport && (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Tabs
            selectedTab={selectedTab}
            onTabChange={(tabIndex) => setSelectedTab(tabIndex)}
          >
            <Tab title="Teams">
              <Typography variant="subtitle1" mb={2}>
                Top Teams
              </Typography>
              <Table id="top-teams" data={teams} columns={topTeamsColumns} />
            </Tab>
            <Tab title="Players">
              <Typography variant="subtitle1" mb={2}>
                Top Players
              </Typography>
              <Table
                id="top-players"
                data={players}
                columns={topPlayersColumns}
              />
            </Tab>
            <Tab title="Leagues">
              <Typography variant="subtitle1" mb={2}>
                Top Leagues
              </Typography>
              <Table
                id="top-leagues"
                data={leagues}
                columns={topLeaguesColumns}
              />
            </Tab>
          </Tabs>
        </Grid>
      )}
    </Grid>
  );
};
