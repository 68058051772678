import React, { FC, useEffect, useState } from "react";

import { Grid, Typography, Paper } from "@mui/material";
import { GenderChart, AgeChart, ActivityChart, ValuePanel, PiePanel, TopPanel, SpendPanel } from "@/components/molecules";
import { Panel, SurveyPanel } from "@/components/atoms"
import {
  BarChartDataType,
  ChartDataType,
} from "@/components/molecules/charts/charts-types";
import {
  computeActivityData,
  computeAgeData,
  computeGenderData,
} from "@/components/molecules/charts/charts-utils";
import {Demographics, OpportunityResult, SurveyGroup} from "@/types";


type FanSummaryTabProps = {
  demographics: Demographics;
  surveyResults: SurveyGroup[];
  opportunityResults: OpportunityResult[];
};

export const FanSummaryTab: FC<FanSummaryTabProps> = ({ demographics, surveyResults, opportunityResults }) => {
  const [genderData, setGenderData] = useState<ChartDataType>({
    groups: [],
    types: [],
  });
  const [ageData, setAgeData] = useState<BarChartDataType>({
    labels: [],
    values: [],
    baseline: [],
  });
  const [activityData, setActivityData] = useState<BarChartDataType>({
    labels: [],
    values: [],
    baseline: [],
  });

  useEffect(() => {
    setActivityData(computeActivityData(demographics.activity));
    setAgeData(computeAgeData(demographics.age));
    setGenderData(computeGenderData(demographics.gender));
  }, [demographics]);

  return (
    <Grid container item xs={12} spacing={4} alignItems="flex-start" mb={12}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom={false}>Demographics</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
        <Panel title="Age">
          <AgeChart labels={ageData.labels} values={ageData.values} baseline={ageData.baseline} />
        </Panel>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
        <Panel title="Gender">
          <GenderChart groups={genderData.groups} types={genderData.types} />
        </Panel>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
        <Panel title="Activity">
          <ActivityChart labels={activityData.labels} values={activityData.values} baseline={activityData.baseline} />
        </Panel>
      </Grid>
      {opportunityResults ?
        <>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom={false}>Fan Opportunity</Typography>
          </Grid>
          {opportunityResults.map(({name, value}, index) => {
            return (
              <Grid key={`opportunity-panel-${index}`} item xs={6} sm={6} md={4} lg={3} xl={2.4}>
                <SpendPanel title={name} value={value}/>
              </Grid>
            );
          })}
        </>
        : null}
      {surveyResults ?
        <>
          <Grid item xs={12}>
              <Typography variant="h6" gutterBottom={false}>Fan Engagement</Typography>
          </Grid>
            {surveyResults.map(({label, type, description, results}, index) => {
              let PanelComponent;
                if (type === 'Value') {
                PanelComponent = ValuePanel;
              } else if (type === 'Chart') {
                PanelComponent = PiePanel;
              } else if (type === 'Top') {
                  PanelComponent = TopPanel;
              } else {
                PanelComponent = ValuePanel;
              }
              return (
                <Grid key={`survey-panel-${index}`} item xs={6} sm={6} md={4} lg={3} xl={2.4}>
                <PanelComponent title={label} description={description} results={results}/>
                </Grid>
              );
            })}
        </>
      : null}
    </Grid>
  );
};
